import React from 'react'
import {Redirect} from '@reach/router'
import Layout from '../components/layout/Layout'
import Headline from '../components/headline/Headline'
import Text from '../components/text/Text'
import Container from '../components/container/Container'
import {graphql} from 'gatsby'
import Helmet from 'react-helmet'


const Legal = ({data: {legal}, pageContext}) => {

    if (!legal) return <Redirect to={`/${pageContext.locale}/404`} noThrow/>

    return (
        <Layout
            locale={pageContext.locale}
            lang={pageContext.lang}
            alternatePath={pageContext.alternatePath}
            alternateLocale={pageContext.alternateLocale}
        >

            <Helmet>
                <title>{`Aquilas — ${legal.data.headline.text}`}</title>
            </Helmet>

            <Headline headline={legal.data.headline}/>
            <Container>
                <Text text={legal.data.text}/>
            </Container>
        </Layout>
    )
}


export const query = graphql`
query imprintQuery($lang: String!)  {
  legal: prismicImprint(lang:{eq:$lang }) {
    data {
      headline {
        text
      }
      text {
        html
      }
    }
  }
}
`

export default Legal